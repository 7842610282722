import { animate, scroll, inView, stagger } from 'motion'

document.querySelectorAll('.fade-in-out').forEach((item) => {
  scroll(animate(item, { opacity: [0, 1, 1, 0] }), {
    target: item,
    offset: ['start end', 'end end', 'start start', 'end start'],
  })
})

let mq = window.matchMedia('(min-width: 768px)')
if (mq.matches) {
  document.querySelectorAll('[data-speed]').forEach((item) => {
    const speedSetting = item.getAttribute('data-speed')
    if (speedSetting) {
      const speed = parseFloat(speedSetting) || 100
      scroll(animate(item, { y: [-speed, speed] }), {
        target: item,
        offset: ['end start', 'start end'],
      })
    }
  })
}

inView(
  '[data-appear]',
  ({ target }) => {
    animate(
      target,
      { opacity: 1, y: [100, 0], transform: 'none' },
      { delay: 0.2, duration: 0.9, easing: [0.17, 0.55, 0.55, 1] }
    )
  },
  {
    amount: 0.4,
  }
)

// Gallery Images
const galleryImgs: NodeListOf<HTMLImageElement> =
  document.querySelectorAll('[data-gallery] img')
galleryImgs.forEach((img) => (img.style.opacity = '0'))
inView(
  '[data-gallery]',
  ({ target }) => {
    const imgs = target.querySelectorAll('img')
    animate(
      imgs,
      { opacity: 1, transform: 'none' },
      { duration: 0.9, easing: [0.17, 0.55, 0.55, 1], delay: stagger(0.15) }
    )
  },
  {
    amount: 0.4,
  }
)

const listEls: NodeListOf<HTMLImageElement> = document.querySelectorAll(
  '[data-list-appear] li'
)
listEls.forEach((el) => (el.style.opacity = '0'))
inView('[data-list-appear]', ({ target }) => {
  const els = target.querySelectorAll('li')
  animate(
    els,
    { opacity: 1, transform: 'none' },
    { duration: 0.9, easing: [0.17, 0.55, 0.55, 1], delay: stagger(0.15) }
  )
})
