import { AlpineComponent } from 'alpinejs'
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css/pagination'

export function Slider(
  this: AlpineComponent,
  config: SwiperOptions
): AlpineComponent {
  const defaultConfig: SwiperOptions = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    navigation: {
      nextEl: this.$refs.next,
      prevEl: this.$refs.prev,
      disabledClass: 'opacity-0',
    },
    pagination: {
      el: this.$refs.pagination,
      type: 'bullets',
      clickable: true,
      bulletClass:
        'inline-block rounded-full w-1.5 h-1.5 ring-1 ring-gray-300 ring-offset-2 ring-offset-white cursor-pointer transition-all duration-200 hover:ring-gray-400 hover:bg-gray-200',
      bulletActiveClass: '!bg-blue-500 !ring-blue-700',
    },
  }

  return {
    swiper: null,

    init() {
      this.swiper = new Swiper(this.$refs.slider, {
        ...defaultConfig,
        ...config,
      })
    },

    discard() {
      this.swiper.destroy()
    },
  }
}
