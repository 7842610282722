import { AlpineComponent } from 'alpinejs'
import { interpolate } from 'flubber'
import { animate } from 'motion'

const paths = {
  plus: {
    d: 'M12.2 10.569v-9.8h-2v9.8H.4v2h9.8v9.8h2v-9.8H22v-2h-9.8Z',
  },
  arrow: {
    d: 'M17.07 11.123 6.716.77l-1.83 1.83 8.969 8.97-8.97 8.969 1.83 1.83 10.8-10.799-.445-.446Z',
  },
}

// <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 23">
//   <path fill="#fff" d="M12.2 10.569v-9.8h-2v9.8H.4v2h9.8v9.8h2v-9.8H22v-2h-9.8Z"/>
//   <path fill="#fff" d="M17.07 11.123 6.716.77l-1.83 1.83 8.969 8.97-8.97 8.969 1.83 1.83 10.8-10.799-.445-.446Z"/>
// </svg>

export function Button(): AlpineComponent {
  return {
    path: '',
    currentPath: '',

    init() {
      this.path = this.$el.querySelector('path')
      this.currentPath = paths.plus

      this.path.setAttribute('d', this.currentPath.d)

      this.$el.addEventListener('mouseover', this.togglePath.bind(this))
      this.$el.addEventListener('mouseout', this.togglePath.bind(this))
    },

    togglePath() {
      const transition = { duration: 0.2, ease: 'easeInOut' }

      this.currentPath =
        this.currentPath.d === paths.plus.d ? paths.arrow : paths.plus

      const mixPaths = interpolate(
        this.path.getAttribute('d'),
        this.currentPath.d,
        {
          maxSegmentLength: 0.5,
        }
      )

      animate(
        (progress) => this.path.setAttribute('d', mixPaths(progress)),
        transition
      )
    },
  }
}
