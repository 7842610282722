import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import interset from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
import { Slider } from '@js/slider.ts'
import { Button } from '@js/button.ts'
import './animations.ts'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(interset)
Alpine.data('slider', Slider)
Alpine.data('button', Button)
Alpine.start()
